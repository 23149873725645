@import-normalize; /* bring in normalize.css styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --dvh: 100vh; /* Fallback for browsers that don't support dvh */
  --transition-duration: 0.5s;
}

@supports (height: 100dvh) {
  :root {
    --dvh: 100dvh;
  }
}

body {
  margin: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  transition:
    background-color var(--transition-duration),
    color var(--transition-duration);
}

body.modal-open {
  overflow: hidden;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* @media not all and (hover: none) and (pointer: coarse) { */
*::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* Background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: #fff;
}
*::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* Scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* Set button (top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}
/* } */

.dark *::-webkit-scrollbar {
  background-color: #1b1b1d;
  width: 16px;
}

/* Background of the scrollbar except button or resizer */
.dark *::-webkit-scrollbar-track {
  background-color: #1b1b1d;
}
.dark *::-webkit-scrollbar-track:hover {
  background-color: #2c2c2f;
}

/* Scrollbar itself */
.dark *::-webkit-scrollbar-thumb {
  background-color: #4a4a4f;
  border-radius: 16px;
  border: 5px solid #1b1b1d;
}
.dark *::-webkit-scrollbar-thumb:hover {
  background-color: #606065;
  border: 4px solid #2c2c2f;
}

/* Set button (top and bottom of the scrollbar) */
.dark *::-webkit-scrollbar-button {
  display: none;
}

.stacked-shadow {
  box-shadow:
    0 1px 0 #bdbdbd,
    0 2px 0 #fff,
    0 3px 0 #bdbdbd,
    0 4px 0 #fff,
    0 5px 0 #bdbdbd,
    0 0 0 1px #bdbdbd;
}

.dark .stacked-shadow {
  box-shadow:
    0 1px 0 #d0c7c7,
    0 2px 0 #000,
    0 3px 0 #9f9a9a,
    0 4px 0 #000,
    0 5px 0 #5d5757,
    0 0 0 1px #898686;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 40px;
  height: 20px;
  --c: no-repeat radial-gradient(farthest-side, #9ca3af 93%, #0000); /* Updated color */
  background:
    var(--c) 0 0,
    var(--c) 50% 0;
  background-size: 8px 8px;
  position: relative;
  clip-path: inset(-200% -100% 0 0);
  animation: l6-0 1.5s linear infinite;
}

.loader:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 12px;
  background: #9ca3af; /* Updated color */
  left: -16px;
  top: 0;
  animation:
    l6-1 1.5s linear infinite,
    l6-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
}

.loader:after {
  content: '';
  position: absolute;
  inset: 0 0 auto auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #9ca3af; /* Updated color */
  animation: l6-3 1.5s linear infinite;
}

@keyframes l6-0 {
  0%,
  30% {
    background-position:
      0 0,
      50% 0;
  }
  33% {
    background-position:
      0 100%,
      50% 0;
  }
  41%,
  63% {
    background-position:
      0 0,
      50% 0;
  }
  66% {
    background-position:
      0 0,
      50% 100%;
  }
  74%,
  100% {
    background-position:
      0 0,
      50% 0;
  }
}

@keyframes l6-1 {
  90% {
    transform: translateY(0);
  }
  95% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(15px);
    left: calc(100% - 8px);
  }
}

@keyframes l6-2 {
  100% {
    top: -0.1px;
  }
}

@keyframes l6-3 {
  0%,
  80%,
  100% {
    transform: translate(0);
  }
  90% {
    transform: translate(26px);
  }
}
